<template>
  <div>
    <v-expansion-panel popout>
      <v-expansion-panel-content
        v-for="(item, i) in 3"
        :key="i"
      >
        <template v-slot:header>
          <div v-if="item == 1">
            {{ $t('generalConfiguration.smtpConfigurationInfo') }}
          </div>
          <div v-else-if="item == 2 && smtpUsePrivate && hasSmtpProjectAlready()">
            {{ $t('generalConfiguration.emailConfiguration') }}
          </div>
          <div v-else-if="!forConasi && smtpUsePrivate && hasSmtpProjectAlready()">
            {{ $t('generalConfiguration.mailGatewayConfig') }}
          </div>
        </template>
        <template>
          <div v-if="item == 1">
            <v-container grid-list-xl>
              <v-form
                ref="form"
                lazy-validation>
                <v-layout
                  wrap
                  layout
                >
                  <v-flex
                    xs12
                    sm12
                    md12
                  >
                    <v-checkbox
                      v-model="smtpUsePrivate"
                      :label="$t('customization.usedPrivateSmtp')"
                      :disabled="isEntityDisabled()"
                      @change="usedEmailSystemChange"
                    />
                  </v-flex>
                  <v-flex
                    v-if="isAdmin() && smtpUsePrivate"
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      v-model="smtp_project.project_name"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.name')"
                    />
                  </v-flex>
                  <v-flex
                    v-if="smtpUsePrivate"
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      v-model="smtp_project.alias"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.alias')"
                    />
                  </v-flex>
                  <v-flex
                    v-if="isAdmin() && smtpUsePrivate"
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      v-model="smtp_project.description"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.description')"
                    />
                  </v-flex>
                  <v-flex
                    v-if="smtpUsePrivate"
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      v-model="smtp_project.page_size"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.pageSize')"
                    />
                  </v-flex>
                  <v-flex
                    v-if="smtpUsePrivate"
                    xs12
                    sm6
                    md6
                  >
                    <v-textarea
                      v-model="smtp_project.sub_content"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.subContent')"
                    />
                  </v-flex>
                </v-layout>
                <v-spacer/>
                <v-btn
                  v-if="smtpUsePrivate"
                  :loading="isLoading"
                  :disabled="isEntityDisabled()"
                  color="success darken-1"
                  small
                  class="pull-right"
                  @click="saveSMTPProject"
                >{{ $t('common.save') }}</v-btn>
              </v-form>
            </v-container>
          </div>
          <div v-else-if="item == 2">
            <v-container
              v-if="(smtpUsePrivate && hasSmtpProjectAlready())"
              grid-list-xl>
              <v-form
                ref="form"
                lazy-validation>
                <v-layout
                  wrap
                  layout
                >
                  <v-flex
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      :rules="[ruleValidUrlPath]"
                      v-model="smtp_email.email_host"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.serverSMTP')"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      :rules="[ruleRequiredValue]"
                      v-model="smtp_email.port"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.port')"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      :rules="[ruleValidEmail]"
                      v-model="smtp_email.email_user_host"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.emailAccount')"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                  >
                    <v-text-field
                      v-model="smtp_email.password_email"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.password')"
                      type="password"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                  >
                    <v-checkbox
                      :label="$t('generalConfiguration.useTLS')"
                      :disabled="isEntityDisabled()"
                      v-model="smtp_email.email_user_tls"/>
                  </v-flex>
                </v-layout>
                <v-spacer/>
                <v-btn
                  :loading="isLoading"
                  :disabled="isEntityDisabled()"
                  color="success darken-1"
                  small
                  class="pull-right"
                  @click="saveSMTPEmailServer"
                >{{ $t('common.save') }}</v-btn>
                <v-btn
                  :loading="isLoading"
                  :disabled="isEntityDisabled()"
                  color="info darken-1"
                  small
                  class="pull-right"
                  @click="sendTestEmail"
                >{{ $t('generalConfiguration.sendTestEmail') }}</v-btn>
              </v-form>
            </v-container>
          </div>
          <div v-else-if="!forConasi">
            <v-container
              v-if="(smtpUsePrivate && hasSmtpProjectAlready())"
              grid-list-xl>
              <v-form>
                <v-layout
                  wrap
                  layout
                >
                  <v-flex
                    xs12
                    sm12
                    md12
                  >
                    <v-text-field
                      :rules="[ruleValidUrlPath]"
                      v-model="mailGateway.baseUrl"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.mailGatewayURL')"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm12
                    md12
                  >
                    <v-text-field
                      v-model="mailGateway.apikey"
                      :disabled="isEntityDisabled()"
                      :label="$t('generalConfiguration.mailGatewayApikey')"
                      type="password"
                    />
                  </v-flex>
                </v-layout>
                <v-spacer/>
                <v-btn
                  :loading="isLoading"
                  :disabled="isEntityDisabled()"
                  small
                  color="success darken-1"
                  class="pull-right"
                  @click="saveMailGatewayConfiguration"
                >{{ $t('common.save') }}</v-btn>
              </v-form>
            </v-container>
          </div>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <sendTestEmail
      ref="sendTestEmail"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import validationRules from 'utils/validationRules'
import SendTestEmail from './SendTestEmail'
import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  components: {
    SendTestEmail
  },
  props: {
    entityId: { type: Number, default: () => null }
  },
  data: () => ({
    smtp_email: {
      email_host: null,
      email_user_host: null,
      port: null,
      email_user_tls: true,
      password_email: null
    },
    smtp_project: {
      project_name: null,
      alias: null,
      sub_content: null,
      page_size: 10,
      description: null
    },
    mailGateway: {
      baseUrl: null,
      apikey: null
    },
    smtpProjectId: null,
    smtpUsePrivate: false,
    forConasi: FOR_CONASI_TARGET,
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    entityId () {
      this.getEntitySmtpConfig()
      this.getSMTPProject()
      this.getSMTPEmailServer()
      if (!FOR_CONASI_TARGET) {
        this.getMailGatewayConfiguration()
      }
    }
  },
  created () {
    this.getEntitySmtpConfig()
    this.getSMTPProject()
    this.getSMTPEmailServer()
    if (!FOR_CONASI_TARGET) {
      this.getMailGatewayConfiguration()
    }
  },
  methods: {
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleValidEmail (value) {
      return validationRules.ruleValidEmailFormat(value)
    },
    ruleValidUrlPath (value) {
      return validationRules.ruleValidUrlPath(value)
    },
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    getEntitySmtpConfig: function () {
      let entityId = this.entityId
      if (!this.isAdmin()) {
        entityId = sessionStorage.getItem('entityId')
      }
      this.GET_ENTITY_SMTP_CONFIGURATION({ id: entityId }).then(
        function (res) {
          let data = res.data
          this.smtpUsePrivate = data.smtp_use_private
          this.smtpProjectId = data.smtp_project_id
        }.bind(this)
      )
    },
    /**
     * Used email system change
     */
    usedEmailSystemChange: function () {
      let entityId = this.entityId
      if (!this.isAdmin()) {
        if (this.isEntityDisabled()) {
          return // Locked or expired entity will not able to perform these actions
        }
        entityId = sessionStorage.getItem('entityId')
      }
      let filter = {
        entityId: entityId,
        usePrivateSmtp: this.smtpUsePrivate
      }
      this.SET_USE_PRIVATE_SMTP(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    hasSmtpProjectAlready: function () {
      return (!functionUtils.isNull(this.smtpProjectId) && this.smtpProjectId !== 'null' && this.smtpProjectId !== 'undefined')
    },
    /**
     * Get SMTP project
     */
    getSMTPProject: function () {
      let entityId = this.entityId
      if (!this.isAdmin()) {
        entityId = sessionStorage.getItem('entityId')
      }
      let filter = {
        params: {
          entityId: entityId
        }
      }
      this.GET_SMTP_PROJECT(filter).then(
        function (res) {
          let data = res.data
          this.smtp_project.project_name = data.project_name && data.project_name
          this.smtp_project.alias = data.alias && data.alias
          this.smtp_project.sub_content = data.tail_content && data.tail_content
          this.smtp_project.page_size = data.pagination && data.pagination
          this.smtp_project.description = data.description && data.description
        }.bind(this)
      )
    },
    /**
     * Save SMTP Project
     */
    saveSMTPProject: function () {
      let entityId = this.entityId
      if (!this.isAdmin()) {
        entityId = sessionStorage.getItem('entityId')
        if (this.isEntityDisabled()) {
          return // Locked or expired entity will not able to perform these actions
        }
      }
      let data = {
        project_name: this.smtp_project.project_name,
        alias: this.smtp_project.alias,
        page_size: this.smtp_project.page_size,
        sub_content: this.smtp_project.sub_content,
        description: this.smtp_project.description,
        entity_id: entityId
      }
      this.isLoading = true
      this.PUSH_SMTP_PROJECT(data).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getEntitySmtpConfig()
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Save SMTP Server
     */
    saveSMTPEmailServer: function () {
      let entityId = this.entityId
      if (!this.isAdmin()) {
        entityId = sessionStorage.getItem('entityId')
        if (this.isEntityDisabled()) {
          return // Locked or expired entity will not able to perform these actions
        }
      }
      let data = {
        email_host: this.smtp_email.email_host,
        email_user_host: this.smtp_email.email_user_host,
        password_email: this.smtp_email.password_email,
        email_user_tls: this.smtp_email.email_user_tls,
        port: this.smtp_email.port,
        entity_id: entityId
      }
      this.isLoading = true
      this.PUSH_CONFIG_SMTP_EMAIL_SERVER(data).then(
        function () {
          this.isLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getSMTPEmailServer()
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Get SMTP Email server
     */
    getSMTPEmailServer: function () {
      let entityId = this.entityId
      if (!this.isAdmin()) {
        entityId = sessionStorage.getItem('entityId')
      }
      let filter = {
        params: {
          entityId: entityId
        }
      }
      this.GET_CONFIG_SMTP_EMAIL_SERVER(filter).then(
        function (res) {
          let data = res.data
          this.smtp_email.email_host = data.email_host && data.email_host
          this.smtp_email.email_user_host = data.email_user_host && data.email_user_host
          this.smtp_email.port = data.port && data.port
          this.smtp_email.email_user_tls = data.email_user_tls && data.email_user_tls
          this.smtp_email.password_email = null
        }.bind(this)
      )
    },
    getMailGatewayConfiguration: function () {
      let entityId = this.entityId
      if (!this.isAdmin()) {
        entityId = sessionStorage.getItem('entityId')
      }
      this.GET_ENTITY_MAIL_GATEWAY_CONFIGURATION({ id: entityId }).then(
        function (res) {
          let data = res.data
          this.mailGateway.apikey = data.mail_gateway_api_key
          this.mailGateway.baseUrl = data.mail_gateway_url
        }.bind(this)
      )
    },
    saveMailGatewayConfiguration: function () {
      let filter = {
        mailGatewayApiKey: this.mailGateway.apikey,
        mailGatewayURL: this.mailGateway.baseUrl
      }
      let entityId = this.entityId
      if (!this.isAdmin()) {
        entityId = sessionStorage.getItem('entityId')
        if (this.isEntityDisabled()) {
          return // Locked or expired entity will not able to perform these actions
        }
      }
      this.SET_ENTITY_MAIL_GATEWAY_CONFIGURATION({ id: entityId, ...filter }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    sendTestEmail: function () {
      this.$refs.sendTestEmail.onShowModal()
    },
    ...mapActions([
      'SET_USE_PRIVATE_SMTP',
      'GET_SMTP_PROJECT',
      'GET_ENTITY_SMTP_CONFIGURATION',
      'PUSH_SMTP_PROJECT',
      'GET_CONFIG_SMTP_EMAIL_SERVER',
      'PUSH_CONFIG_SMTP_EMAIL_SERVER',
      'SET_ENTITY_MAIL_GATEWAY_CONFIGURATION',
      'GET_ENTITY_MAIL_GATEWAY_CONFIGURATION',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
