import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'utils/functionUtils'

// state
const state = {
  smtp_server: [],
  smtp_project: [],
  smtp_email_server: [],
  mediaServer: [],
  mediaProject: [],
  tokenMedia: [],
  generalConfig: []
}

// getters
const getters = {
  GET_CONFIG_SMTP_EMAIL_SERVER_DATA: (state, payload) => {
    return state.smtp_email_server
  },
  GET_SMTP_CONFIG_DATA: (state, payload) => {
    return state.smtp_server
  },
  GET_SMTP_PROJECT_DATA: (state, payload) => {
    return state.smtp_project
  },
  GET_MEDIA_CONFIG_DATA: (state, payload) => {
    return state.mediaServer
  },
  GET_MEDIA_PROJECT_DATA: (state, payload) => {
    return state.mediaProject
  },
  GET_TOKEN_MEDIA_SERVER_DATA: (state, payload) => {
    return state.tokenMedia
  },
  GET_GENERAL_CONFIG_HANDLER: (state, payload) => {
    return state.generalConfig
  }
}

const actions = {
  GET_GENERAL_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_general_config/`,
        payload,
        function (res) {
          context.commit('GET_GENERAL_CONFIG_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_GENERAL_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/update_general_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_SMTP_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/smtp_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SMTP_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_smtp_config/`,
        payload,
        function (res) {
          context.commit('GET_SMTP_CONFIG', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_smtp_project/`,
        payload,
        function (res) {
          context.commit('GET_SMTP_PROJECT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/put_smtp_project/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CONFIG_SMTP_EMAIL_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_config_smtp_email_server/`,
        payload,
        function (res) {
          context.commit('GET_CONFIG_SMTP_EMAIL_SERVER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_CONFIG_SMTP_EMAIL_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/${currentEntityId()}/customizationtable/put_config_smtp_email_server/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ALL_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_smtp_project/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CONNECT_SMTP_CHECK_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/connect_smtp_check_project/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_MEDIA_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/media_config/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_MEDIA_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_media_config/`,
        payload,
        function (res) {
          context.commit('GET_MEDIA_CONFIG', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_MEDIA_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_media_project/`,
        payload,
        function (res) {
          context.commit('GET_MEDIA_PROJECT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_MEDIA_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/put_media_project/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TOKEN_MEDIA_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_token_media_server/`,
        payload,
        function (res) {
          context.commit('GET_TOKEN_MEDIA_SERVER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SAVE_SMS_CONFIG_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/save_sms_config_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SMS_CONFIG_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/${currentEntityId()}/customizationtable/get_sms_config_info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ON_CHANGE_PASSWORD_FOR_SERVICE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/${currentEntityId()}/customizationtable/change_password_for_service/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_GENERAL_CONFIG_HANDLER: (state, payload) => {
    state.generalConfig = payload
  },
  GET_SMTP_CONFIG: (state, payload) => {
    state.smtp_server = payload
  },
  GET_SMTP_PROJECT: (state, payload) => {
    state.smtp_project = payload
  },
  GET_CONFIG_SMTP_EMAIL_SERVER: (state, payload) => {
    state.smtp_email_server = payload
  },
  GET_MEDIA_CONFIG: (state, payload) => {
    state.mediaServer = payload
  },
  GET_MEDIA_PROJECT: (state, payload) => {
    state.mediaProject = payload
  },
  GET_TOKEN_MEDIA_SERVER: (state, payload) => {
    state.tokenMedia = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
