import StoreChannelType from 'enum/storeChannelType'
import VoucherReconciliationStatus from 'enum/voucherReconciliationStatus'
import GenderType from 'enum/genderType'
import EntityType from 'enum/entityType'
import EntityRoleType from 'enum/entityRoleType'
import SysRoleType from 'enum/sysRoleType'
import PaymentMethodType from 'enum/paymentMethodType'
import VoucherStatusType from 'enum/voucherStatusType'
import VoucherHistoryType from 'enum/voucherHistoryType'
import BankTransactionStatusEnum from 'enum/bankTransactionStatusEnum'
import EntityStatus from 'enum/entityStatus'
import jwtDecode from 'jwt-decode'
// import languages from 'store/modules/settings/data.js'
import { languages } from '../store/modules/settings/data'
import i18n from 'lang/langUtils'
import dateUtils from 'utils/dateUtils'
import moment from 'moment'
/**
 * Check null
 * @param {*} data
 */
const isNull = function (data) {
  if (data === undefined || data === null) {
    return true
  } else {
    return false
  }
}
/**
 * Check permission
 * @param {*} userInfo
 * @param {*} perm
 */
const hasAction = function (userInfo, perm) {
  if (isSuperAdmin(userInfo)) {
    return true
  }
  let actions = userInfo.actions ? userInfo.actions : []
  for (let i = 0; i < actions.length; i++) {
    let currentPerm = actions[i]
    if (currentPerm === perm) {
      return true
    }
  }
  return false
}
/**
 * Check is super user
 * @param {*} currentUser
 */
const isSuperAdmin = function (currentUser) {
  let userId = currentUser ? currentUser.id : null
  if (userId === 1) {
    return true
  } else {
    return false
  }
}
const isAdmin = function (currentUser) {
  return isSuperAdmin(currentUser) || hasSystemRole(SysRoleType.ADMIN, currentUser)
}
/**
 * Get first name and last name from full name
 * @param {*} fullName
 */
const getFirstLastName = function (fullName) {
  let firstName = ''
  let lastName = ''
  if (fullName) {
    // Split full name to get last name and first name
    let fullNameArray = fullName.split(' ')
    firstName = fullNameArray[fullNameArray.length - 1]
    lastName = ''
    for (let i = 0, size = fullNameArray.length - 1; i < size; i++) {
      lastName += fullNameArray[i] + (i < size - 1 ? ' ' : '')
    }
  }
  return {
    'firstName': firstName,
    'lastName': lastName
  }
}
/**
 * Validate email
 * @param {*} email
 */
const validateEmail = function (email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
const validateFullname = function (fullname) {
  if ((fullname === undefined) || (fullname === null) || (typeof fullname !== 'string')) {
    return false
  }
  return fullname.trim().indexOf(' ') !== -1
}
/**
 * Has special character
 * @param {*} string
 */
const hasSpecialCharacter = function (string) {
  // eslint-disable-next-line no-useless-escape
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (format.test(string)) {
    return true
  } else {
    return false
  }
}
/**
 * Remove space in string
 * @param {*} str
 */
const removeSpaceInString = function (str) {
  return str.replace(/\s/g, '')
}
/**
 * Convert number to money
 * @param {*} number
 */
const convertFormatNumberToMoney = function (number) {
  return Number(number).toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? '.' + c : c
  })
}
/**
 * Convert number money to normal number
 * @param {*} num
 */
const formatInteger = function (num) {
  num = !isNull(num) ? num : ''
  if (num.length > 3) {
    return num.toString().replace(/[^A-Z0-9]/ig, '')
  }
  return num
}
/**
 * Check is supplier
 * @param {*} value
 */
const isSupplier = function (user) {
  let entity = user.entity
  if (!isNull(entity) && entity.type === EntityType.SUPPLIER) {
    return true
  }
  return false
}
const isEntityLocked = function (status) {
  if (status !== undefined && status != null && parseInt(status) === EntityStatus.INACTIVE) {
    return true
  }
  return false
}
const isEntityExpired = function (expiredDate) {
  if (expiredDate === undefined || expiredDate == null || expiredDate === '') {
    return false // Entity has no expired date
  }
  let today = new Date()
  today.setHours(0, 0, 0, 0)
  expiredDate = moment(expiredDate, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE)
  if (expiredDate < today) {
    return true // Entity has expired
  }
  return false // Entity still valid using
}
const isCurrentEntityLocked = function () {
  let status = getSessionKey('entityStatus')
  return isEntityLocked(status)
}
const isCurrentEntityExpired = function () {
  let expiredDate = getSessionKey('expiredDateEntity')
  return isEntityExpired(expiredDate)
}
/**
 * Check entity type
 * @param {*} entityType
 */
const isEntityType = function (entityType) {
  let currentEntityType = sessionStorage.getItem('entityType')
  // eslint-disable-next-line eqeqeq
  if (!isNull(currentEntityType) && currentEntityType == entityType) {
    return true
  }
  return false
}
/**
 * Check is agent
 * @param {*} value
 */
const isAgent = function (user) {
  let entity = user.entity
  if (!isNull(entity) && entity.type === EntityType.AGENT) {
    return true
  }
  return false
}
/**
 * Check is agent
 * @param {*} value
 */
const isCustomer = function (user) {
  let entity = user.entity
  if (!isNull(entity) && entity.type === EntityType.CUSTOMER) {
    return true
  }
  return false
}
/**
 * Check has role
 * @param {*} roleId
 * @param {*} user
 */
const hasSystemRole = function (sysRole, user) {
  if (sysRole === user.sys_role) {
    return true
  }
  return false
}
const validatePhone = function (phone) {
  // const regex = /^((0|1)[1-9][0-9]{8}|[+]84[1-9][0-9]{8})$/
  const regex = /^((0|1)[1-9][0-9]{8}|[+]84[1-9][0-9]{8}|(02)[1-9][0-9]{8})$/
  return !!regex.test(phone)
}
const validUsername = function (username) {
  var usernameRegex = /^[a-zA-Z0-9]+$/
  return username.match(usernameRegex)
}

/**
 * Check empty string
 * @param {*} value
 */
const isEmptyString = function (value) {
  return (value === undefined) || (value === null) || (typeof value === 'string' && value.trim().length === 0)
}

const getStringValue = function (value, defValue = null) {
  if ((value === undefined) || (value === null)) return defValue
  if (typeof value === 'string') {
    value = value.trim()
    return value.length === 0 ? defValue : value
  }
  return value
}

const getNumberValue = function (value, defValue = null) {
  if ((value === undefined) || (value === null)) return defValue
  return Number(value)
}

const hasEntityRoles = function (key) {
  let entityRoles = sessionStorage.getItem('entityRoles')
  entityRoles = entityRoles ? JSON.parse(entityRoles) : []
  for (let i = 0, size = entityRoles.length; i < size; i++) {
    // eslint-disable-next-line eqeqeq
    if (key == entityRoles[i]) {
      return true
    }
  }
}
/**
 * Check entity type
 * @param {*} entityType
 */
const roleInEntity = function (roleType) {
  let roleEntity = sessionStorage.getItem('roleInEntity')
  if (!isNull(roleEntity) && parseInt(roleEntity) === roleType) {
    return true
  }
  return false
}

/**
* Secure Hash Algorithm (SHA256)
* http://www.webtoolkit.info/
* Original code by Angel Marin, Paul Johnston
**/

const SHA256 = function (s) {
  var chrsz = 8
  var hexcase = 0

  // eslint-disable-next-line camelcase
  function safe_add (x, y) {
    var lsw = (x & 0xFFFF) + (y & 0xFFFF)
    var msw = (x >> 16) + (y >> 16) + (lsw >> 16)
    return (msw << 16) | (lsw & 0xFFFF)
  }

  function S (X, n) { return (X >>> n) | (X << (32 - n)) }
  function R (X, n) { return (X >>> n) }
  function Ch (x, y, z) { return ((x & y) ^ ((~x) & z)) }
  function Maj (x, y, z) { return ((x & y) ^ (x & z) ^ (y & z)) }
  function Sigma0256 (x) { return (S(x, 2) ^ S(x, 13) ^ S(x, 22)) }
  function Sigma1256 (x) { return (S(x, 6) ^ S(x, 11) ^ S(x, 25)) }
  function Gamma0256 (x) { return (S(x, 7) ^ S(x, 18) ^ R(x, 3)) }
  function Gamma1256 (x) { return (S(x, 17) ^ S(x, 19) ^ R(x, 10)) }

  // eslint-disable-next-line camelcase
  function core_sha256 (m, l) {
    // eslint-disable-next-line no-array-constructor
    var K = new Array(0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1, 0x923F82A4, 0xAB1C5ED5, 0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3, 0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174, 0xE49B69C1, 0xEFBE4786, 0xFC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA, 0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147, 0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13, 0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070, 0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208, 0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2)
    // eslint-disable-next-line no-array-constructor
    var HASH = new Array(0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 0x9B05688C, 0x1F83D9AB, 0x5BE0CD19)
    var W = new Array(64)
    var a, b, c, d, e, f, g, h, i, j
    var T1, T2

    m[l >> 5] |= 0x80 << (24 - l % 32)
    m[((l + 64 >> 9) << 4) + 15] = l

    // eslint-disable-next-line no-redeclare
    for (var i = 0; i < m.length; i += 16) {
      a = HASH[0]
      b = HASH[1]
      c = HASH[2]
      d = HASH[3]
      e = HASH[4]
      f = HASH[5]
      g = HASH[6]
      h = HASH[7]

      // eslint-disable-next-line no-redeclare
      for (var j = 0; j < 64; j++) {
        if (j < 16) W[j] = m[j + i]
        else W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16])

        T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j])
        T2 = safe_add(Sigma0256(a), Maj(a, b, c))

        h = g
        g = f
        f = e
        e = safe_add(d, T1)
        d = c
        c = b
        b = a
        a = safe_add(T1, T2)
      }

      HASH[0] = safe_add(a, HASH[0])
      HASH[1] = safe_add(b, HASH[1])
      HASH[2] = safe_add(c, HASH[2])
      HASH[3] = safe_add(d, HASH[3])
      HASH[4] = safe_add(e, HASH[4])
      HASH[5] = safe_add(f, HASH[5])
      HASH[6] = safe_add(g, HASH[6])
      HASH[7] = safe_add(h, HASH[7])
    }
    return HASH
  }

  function str2binb (str) {
    // eslint-disable-next-line no-array-constructor
    var bin = Array()
    var mask = (1 << chrsz) - 1
    for (var i = 0; i < str.length * chrsz; i += chrsz) {
      bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32)
    }
    return bin
  }

  function Utf8Encode (string) {
    string = string.replace(/\r\n/g, '\n')
    var utftext = ''

    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n)

      if (c < 128) {
        utftext += String.fromCharCode(c)
      } else if ((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192)
        utftext += String.fromCharCode((c & 63) | 128)
      } else {
        utftext += String.fromCharCode((c >> 12) | 224)
        utftext += String.fromCharCode(((c >> 6) & 63) | 128)
        utftext += String.fromCharCode((c & 63) | 128)
      }
    }

    return utftext
  }

  function binb2hex (binarray) {
    // eslint-disable-next-line camelcase
    var hex_tab = hexcase ? '0123456789ABCDEF' : '0123456789abcdef'
    var str = ''
    for (var i = 0; i < binarray.length * 4; i++) {
      str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
  hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF)
    }
    return str
  }

  s = Utf8Encode(s)
  return binb2hex(core_sha256(str2binb(s), s.length * chrsz))
}

const removeDoubleSlashesFromUrl = function (url) {
  return String(url).replace(/([^:]\/)\/+/g, '$1')
}

const getFirstCharacterInString = function (stringValue) {
  let result = ''
  if (!isEmptyString(stringValue) && stringValue !== undefined) {
    if (typeof (stringValue) !== 'string') {
      stringValue = String(stringValue)
    }
    let stringArray = stringValue.split(' ')
    for (let i = 0; i < stringArray.length; i++) {
      let e = stringArray[i]
      if (!isEmptyString(e)) {
        result += e.trim().charAt(0)
      }
    }
  }
  return result
}

const convertToArrayId = function (arr) {
  let ids = []
  let isObject = false
  for (let i = 0, size = arr.length; i < size; i++) {
    if (typeof arr[i] === 'object') {
      ids.push(arr[i].value)
      isObject = true
    }
  }
  if (isObject) {
    return ids
  }
  return arr
}

const getDefaultStoreChannelName = function (type) {
  if (!isNull(type)) {
    type = parseInt(type)
    if (type === StoreChannelType.PRODUCT_PORTAL) {
      return 'Product Portal'
    } else if (type === StoreChannelType.HARAVAN) {
      return 'Haravan'
    } else if (type === StoreChannelType.LIEN_VIET_POST_BANK) {
      return 'LienViet Post Bank'
    } else if (type === StoreChannelType.MOBILE_PORTAL) {
      return 'Mobile Portal'
    } else if (type === StoreChannelType.CONASI_HOME) {
      return 'Conasi Home'
    }
  }
  return ''
}

const concatFullAddress = function (addressInfo, street) {
  var fullAddress = street
  if (addressInfo.ward.type || addressInfo.ward.name) {
    if (!isEmptyString(fullAddress)) {
      fullAddress += ', ' + addressInfo.ward.type + ' ' + addressInfo.ward.name
    } else {
      fullAddress = addressInfo.ward.type + ' ' + addressInfo.ward.name
    }
  }
  if (addressInfo.district.type || addressInfo.district.name) {
    if (!isEmptyString(fullAddress)) {
      fullAddress += ', ' + addressInfo.district.type + ' ' + addressInfo.district.name
    } else {
      fullAddress = addressInfo.district.type + ' ' + addressInfo.district.name
    }
  }
  if (addressInfo.city.type || addressInfo.city.name) {
    if (!isEmptyString(fullAddress)) {
      fullAddress += ', ' + addressInfo.city.type + ' ' + addressInfo.city.name
    } else {
      fullAddress = addressInfo.city.type + ' ' + addressInfo.city.name
    }
  }
  if (!isEmptyString(fullAddress) && addressInfo.country.name) {
    fullAddress += ', ' + addressInfo.country.name
  }
  return fullAddress
}

const concatSuffixPrefix = function (voucherIssuance, defValue = '') {
  if (!isNull(voucherIssuance)) {
    return voucherIssuance.prefix + ' - ' + voucherIssuance.suffix
  }
  return defValue
}

const getReconciliationStatusText = function (reconciliationStatus) {
  if (!isNull(reconciliationStatus)) {
    reconciliationStatus = parseInt(reconciliationStatus)
    if (reconciliationStatus === VoucherReconciliationStatus.WAIT_FOR_APPROVAL) {
      return 'voucherReconciliation.status.waitForApproval'
    } else if (reconciliationStatus === VoucherReconciliationStatus.PREPARE_FOR_SUBMIT) {
      return 'voucherReconciliation.status.preparing'
    } else if (reconciliationStatus === VoucherReconciliationStatus.APPROVED) {
      return 'voucherReconciliation.status.reconciled'
    } else if (reconciliationStatus === VoucherReconciliationStatus.REJECTED) {
      return 'voucherReconciliation.status.canceled'
    } else if (reconciliationStatus === VoucherReconciliationStatus.DELETED) {
      return 'voucherReconciliation.status.deleted'
    } else if (reconciliationStatus === VoucherReconciliationStatus.NOT_REQUEST_YET) {
      return 'voucherReconciliation.status.notSentYet'
    }
  }
  return ''
}

const getVoucherStatusText = function (status) {
  if (!isNull(status)) {
    if (status === VoucherStatusType.INACTIVE) {
      return 'evoucher.status.inactive'
    } else if (status === VoucherStatusType.USING_EXPIRED) {
      return 'evoucher.status.using_expired'
    } else if (status === VoucherStatusType.NOT_ASSIGNED_YET) {
      return 'evoucher.status.notAssignedYet'
    } else if (status === VoucherStatusType.ASSIGNED) {
      return 'evoucher.status.assigned'
    } else if (status === VoucherStatusType.ISSUING) {
      return 'evoucher.status.issuing'
    } else if (status === VoucherStatusType.ACTIVATED) {
      return 'evoucher.status.activated'
    } else if (status === VoucherStatusType.USED) {
      return 'evoucher.status.used'
    } else if (status === VoucherStatusType.DISTRIBUTION_EXPIRED) {
      return 'evoucher.status.distribution_expired'
    } else if (status === VoucherStatusType.LOCKED_USING) {
      return 'evoucher.status.locked'
    } else if (status === VoucherStatusType.WAITING) {
      return 'evoucher.status.waiting'
    } else if (status === VoucherStatusType.RESERVED_ORDER) {
      return 'evoucher.status.reserved_order'
    } else if (status === VoucherStatusType.DISABLED) {
      return 'evoucher.status.voucherDisable'
    }
  }
  return ''
}

const getGender = function (gender) {
  if (!isNull(gender)) {
    gender = parseInt(gender)
    if (gender === GenderType.MALE) {
      return 'userProfile.genderType.male'
    } else if (gender === GenderType.FEMALE) {
      return 'userProfile.genderType.female'
    }
  }
  return 'userProfile.genderType.other'
}

const paymentMethodTypeName = function (paymentMethod) {
  if (!isNull(paymentMethod)) {
    paymentMethod = parseInt(paymentMethod)
    if (paymentMethod === PaymentMethodType.CASH_ON_DELIVERY) {
      return 'payment.type.cash'
    } else if (paymentMethod === PaymentMethodType.BANK_TRANSFER) {
      return 'payment.type.transfer'
    } else if (paymentMethod === PaymentMethodType.MOMO_WALLET) {
      return 'payment.type.momoWallet'
    } else if (paymentMethod === PaymentMethodType.ZALOPAY_WALLET) {
      return 'payment.type.zalopay'
    } else if (paymentMethod === PaymentMethodType.VIET_WALLET) {
      return 'payment.type.viviet'
    } else if (paymentMethod === PaymentMethodType.BAO_KIM_WALLET) {
      return 'payment.type.onlinePayment'
    }
  }
  return ''
}

const sysRoleName = function (sysRole) {
  if (!isNull(sysRole)) {
    sysRole = parseInt(sysRole)
    if (sysRole === SysRoleType.ADMIN) {
      return 'roles.type.admin'
    } else if (sysRole === SysRoleType.REPORTER) {
      return 'roles.type.reporter'
    } //  else if (sysRole === SysRoleType.USER) {
  }
  return 'roles.type.user'
}

const roleInEntityName = function (role) {
  if (!isNull(role)) {
    role = parseInt(role)
    if (role === EntityRoleType.MANAGER) {
      return 'roles.type.manager'
    } else if (role === EntityRoleType.ACCOUNTANT) {
      return 'roles.type.accountant'
    } else if (role === EntityRoleType.STAFF) {
      return 'roles.type.staff'
    }
  }
  return 'roles.type.user'
}

const getEntityTypeName = function (type) {
  if (!isNull(type)) {
    type = parseInt(type)
    if (type === EntityType.SUPPLIER) {
      return 'entity.supplier'
    } else if (type === EntityType.AGENT) {
      return 'entity.agent'
    } else if (type === EntityType.SITE) {
      return 'entity.site'
    }
  }
  return ''
}

const getHistoryActionName = function (type) {
  if (!isNull(type)) {
    if (type === VoucherHistoryType.CREATE) {
      return 'voucherHistory.type.create'
    } else if (type === VoucherHistoryType.DISTRIBUTE) {
      return 'voucherHistory.type.distribute'
    } else if (type === VoucherHistoryType.ASSIGNED) {
      return 'voucherHistory.type.assigned'
    } else if (type === VoucherHistoryType.USED) {
      return 'voucherHistory.type.used'
    } else if (type === VoucherHistoryType.SEND_CONFIRM_USED_REQUEST) {
      return 'voucherHistory.type.sendConfirmUsedRequest'
    } else if (type === VoucherHistoryType.CUSTOMER_CONFIRM_USED) {
      return 'voucherHistory.type.customerConfirmUsed'
    } else if (type === VoucherHistoryType.CUSTOMER_CANCEL_USED) {
      return 'voucherHistory.type.customerCancelUsed'
    } else if (type === VoucherHistoryType.CUSTOMER_RETURN_VOUCHER) {
      return 'voucherHistory.type.customerReturnUsed'
    } else if (type === VoucherHistoryType.CUSTOMER_ASSIGNED_VOUCHER_TO_CUSTOMER) {
      return 'voucherHistory.type.customerDeliveryVoucher'
    } else if (type === VoucherHistoryType.DELETED) {
      return 'voucherHistory.type.deleted'
    } else if (type === VoucherHistoryType.USED_FROM_THIRD_PARTIES) {
      return 'voucherHistory.type.usedFromThirdParties'
    } else if (type === VoucherHistoryType.PROMOTION_USED) {
      return 'voucherHistory.type.usePromotionVoucher'
    } else if (type === VoucherHistoryType.CREATE_PROMOTION_VOUCHER) {
      return 'voucherHistory.type.createPromotionVoucher'
    } else if (type === VoucherHistoryType.RECONCILIATION_APPROVED) {
      return 'voucherHistory.type.approveReconciliation'
    } else if (type === VoucherHistoryType.RECONCILIATION_REJECTED) {
      return 'voucherHistory.type.cancelReconciliation'
    } else if (type === VoucherHistoryType.LOCKED_USING) {
      return 'voucherHistory.type.lockedVoucher'
    } else if (type === VoucherHistoryType.CONFIRMED_USE_LOCKED) {
      return 'voucherHistory.type.confirmUseLockedVoucher'
    } else if (type === VoucherHistoryType.REFUND) {
      return 'voucherHistory.type.refundVoucher'
    } else if (type === VoucherHistoryType.ADMIN_SYSTEM_DELIVERY_VOUCHER_OTHER_CUSTOMER) {
      return 'voucherHistory.type.adminSystemDeliveryVoucher'
    } else if (type === VoucherHistoryType.DISABLE_VOUCHER) {
      return 'voucherHistory.type.disableVoucher'
    } else if (type === VoucherHistoryType.ENABLE_VOUCHER) {
      return 'voucherHistory.type.enableVoucher'
    } else if (type === VoucherHistoryType.RESERVED_ORDER) {
      return 'voucherHistory.type.orderVoucher'
    } else if (type === VoucherHistoryType.ACCOUNTANT_CANCEL_ORDER) {
      return 'voucherHistory.type.cancelOrderVoucher'
    } else if (type === VoucherHistoryType.ACCOUNTANT_CONFIRM_ORDER) {
      return 'voucherHistory.type.confirmOrderVoucher'
    } else if (type === VoucherHistoryType.PUBLISHED_ONTO_STORE_CHANNEL) {
      return 'voucherHistory.type.publishedStoreChannel'
    } else if (type === VoucherHistoryType.TAKE_BACK_FROM_STORE_CHANNEL) {
      return 'voucherHistory.type.takebackStoreChannel'
    } else if (type === VoucherHistoryType.PURCHASE_PAYMENT_STORE_CHANNEL) {
      return 'voucherHistory.type.purchaseOrder'
    } else if (type === VoucherHistoryType.CANCEL_PAYMENT_STORE_CHANNEL) {
      return 'voucherHistory.type.cancelOrder'
    } else if (type === VoucherHistoryType.EXTEND_EXPIRATION) {
      return 'voucherHistory.type.extendExpiration'
    }
  }
  return ''
}

const parseVoucherHistoryDescription = function (type, fromEntity, toEntity, fromUser, toUser, totalVoucher, productOrder, storeChannel) {
  let description = i18n.tc('voucherHistory.description.undefined')
  if (type === VoucherHistoryType.CREATE) {
    description = i18n.t('voucherHistory.description.create', { entity: fromEntity.name, amount: totalVoucher })
  } else if (type === VoucherHistoryType.DISTRIBUTE) {
    description = i18n.t('voucherHistory.description.distribute', { entity: fromEntity.name, amount: totalVoucher, toEntity: toEntity.name })
  } else if (type === VoucherHistoryType.ASSIGNED) {
    description = i18n.t('voucherHistory.description.assigned', { entity: fromEntity.name, amount: totalVoucher, customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email) })
  } else if (type === VoucherHistoryType.USED) {
    description = i18n.t('voucherHistory.description.used', { entity: fromEntity.name, customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email) })
  } else if (type === VoucherHistoryType.SEND_CONFIRM_USED_REQUEST) {
    description = i18n.t('voucherHistory.description.sendConfirmUsedRequest', { entity: fromEntity.name, customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email) })
  } else if (type === VoucherHistoryType.CUSTOMER_CONFIRM_USED) {
    description = i18n.t('voucherHistory.description.customerConfirmUsed', { customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email), entity: fromEntity.name })
  } else if (type === VoucherHistoryType.CUSTOMER_CANCEL_USED) {
    // description = i18n.tc('voucherHistory.description.customerCancelUsed')
  } else if (type === VoucherHistoryType.CUSTOMER_RETURN_VOUCHER) {
    // description = i18n.tc('voucherHistory.description.customerReturnUsed')
  } else if (type === VoucherHistoryType.DELETED) {
    description = i18n.t('voucherHistory.description.deleted', { entity: fromEntity.name })
  } else if (type === VoucherHistoryType.USED_FROM_THIRD_PARTIES) {
    description = i18n.t('voucherHistory.description.usedFromThirdParties', { entity: fromEntity.name, customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email) })
  } else if (type === VoucherHistoryType.CUSTOMER_ASSIGNED_VOUCHER_TO_CUSTOMER) {
    description = i18n.t('voucherHistory.description.customerDeliveryVoucher', { owner: concatFullname(fromUser.last_name, fromUser.first_name, fromUser.email), customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email) })
  } else if (type === VoucherHistoryType.PROMOTION_USED) {
    description = i18n.t('voucherHistory.description.usePromotionVoucher', { entity: fromEntity.name, customer: concatFullname(fromUser.last_name, fromUser.first_name, fromUser.email) })
  } else if (type === VoucherHistoryType.CREATE_PROMOTION_VOUCHER) {
    description = i18n.t('voucherHistory.description.createPromotionVoucher', { entity: fromEntity.name })
  } else if (type === VoucherHistoryType.RECONCILIATION_APPROVED) {
    description = i18n.t('voucherHistory.description.approveReconciliation', { entity: fromEntity.name, requestEntity: toEntity.name })
  } else if (type === VoucherHistoryType.RECONCILIATION_REJECTED) {
    description = i18n.t('voucherHistory.description.cancelReconciliation', { entity: fromEntity.name, requestEntity: toEntity.name })
  } else if (type === VoucherHistoryType.LOCKED_USING) {
    description = i18n.t('voucherHistory.description.lockedVoucher', { entity: fromEntity.name, customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email) })
  } else if (type === VoucherHistoryType.CONFIRMED_USE_LOCKED) {
    description = i18n.t('voucherHistory.description.confirmUseLockedVoucher', { entity: fromEntity.name })
  } else if (type === VoucherHistoryType.REFUND) {
    description = i18n.t('voucherHistory.description.refundVoucher', { entity: fromEntity.name })
  } else if (type === VoucherHistoryType.ADMIN_SYSTEM_DELIVERY_VOUCHER_OTHER_CUSTOMER) {
    description = i18n.t('voucherHistory.description.adminSystemDeliveryVoucher', { customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email) })
  } else if (type === VoucherHistoryType.DISABLE_VOUCHER) {
    description = i18n.t('voucherHistory.description.disableVoucher', { entity: fromEntity.name, customer: concatFullname(toUser.last_name, toUser.first_name, toUser.email) })
  } else if (type === VoucherHistoryType.ENABLE_VOUCHER) {
    description = i18n.t('voucherHistory.description.enableVoucher', { entity: fromEntity.name })
  } else if (type === VoucherHistoryType.RESERVED_ORDER) {
    description = i18n.t('voucherHistory.description.orderVoucher', { storeChannel: storeChannel.name, code: productOrder.code })
  } else if (type === VoucherHistoryType.ACCOUNTANT_CANCEL_ORDER) {
    description = i18n.t('voucherHistory.description.cancelOrderVoucher', { code: productOrder.code, storeChannel: storeChannel.name })
  } else if (type === VoucherHistoryType.ACCOUNTANT_CONFIRM_ORDER) {
    description = i18n.t('voucherHistory.description.confirmOrderVoucher', { code: productOrder.code, storeChannel: storeChannel.name })
  } else if (type === VoucherHistoryType.PUBLISHED_ONTO_STORE_CHANNEL) {
    description = i18n.t('voucherHistory.description.publishedStoreChannel', { storeChannel: storeChannel.name })
  } else if (type === VoucherHistoryType.TAKE_BACK_FROM_STORE_CHANNEL) {
    description = i18n.t('voucherHistory.description.takebackStoreChannel', { storeChannel: storeChannel.name })
  } else if (type === VoucherHistoryType.PURCHASE_PAYMENT_STORE_CHANNEL) {
    description = i18n.t('voucherHistory.description.purchaseOrder', { code: productOrder.code, storeChannel: storeChannel.name })
  } else if (type === VoucherHistoryType.CANCEL_PAYMENT_STORE_CHANNEL) {
    description = i18n.t('voucherHistory.description.cancelOrder', { code: productOrder.code, storeChannel: storeChannel.name })
  } else if (type === VoucherHistoryType.EXTEND_EXPIRATION) {
    description = i18n.tc('voucherHistory.description.extendExpiration')
  }
  return description
}

const isStringTrue = function (text) {
  if (!isEmptyString(text)) {
    if (typeof text === 'string') {
      let arrTrue = ['true', '1', 't', 'y', 'yes', 'yeah', 'yup', 'certainly', 'uh-huh']
      return (arrTrue.indexOf(text.toLowerCase()) > -1)
    } else if (typeof text === 'boolean') {
      return text
    }
  }
  return false
}

const removeVietnameseTones = function (str) {
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/Đ/g, 'D')
  // Remove extra spaces
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  return str
}

const concatFullname = function (lastname, firstname, defValue = '') {
  if (!isEmptyString(firstname) && !isEmptyString(lastname)) {
    return lastname + ' ' + firstname
  } else if (!isEmptyString(firstname)) {
    return firstname
  } else if (!isEmptyString(lastname)) {
    return lastname
  }
  return defValue
}

const randomString = (length = 8) => {
  // Declare all characters
  // let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let str = ''
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return str
}

const ellipsisLongText = function (strText, len) {
  if (!isNull(strText) && strText.length > len) {
    const truncate = (str, len) => str.substring(0, (str + ' ').lastIndexOf(' ', len))
    return truncate(strText, len) + '...'
  }
  return strText
}

const getSessionKey = function (key) {
  let sessionValue = sessionStorage.getItem(key)
  return sessionValue
}

const getBankTransactionStatus = function (status) {
  if (status === BankTransactionStatusEnum.NOT_PROCESS) {
    return {
      'text': 'bankTransactionInfo.statusList.NOT_PROCESS',
      'bgColor': 'grey lighten-1',
      'textColor': ''
    }
  } else if (status === BankTransactionStatusEnum.SUCCESS) {
    return {
      'text': 'bankTransactionInfo.statusList.SUCCESS',
      'bgColor': 'green',
      'textColor': 'white'
    }
  } else if (status === BankTransactionStatusEnum.INVALID_ORDER) {
    return {
      'text': 'bankTransactionInfo.statusList.INVALID_ORDER',
      'bgColor': 'orange',
      'textColor': 'white'
    }
  } else if (status === BankTransactionStatusEnum.INVALID_AMOUNT) {
    return {
      'text': 'bankTransactionInfo.statusList.INVALID_AMOUNT',
      'bgColor': 'yellow accent-2',
      'textColor': ''
    }
  } if (status === BankTransactionStatusEnum.INVALID_APPROVE_ORDER) {
    return {
      'text': 'bankTransactionInfo.statusList.INVALID_APPROVE_ORDER',
      'bgColor': 'purple',
      'textColor': 'white'
    }
  } else {
    return {
      'text': '',
      'bgColor': '',
      'textColor': ''
    }
  }
}

const getStoreChannelsIdInRouteParams = function (routeData) {
  let routerParams = routeData.params
  let storeChannelId = routerParams && routerParams.storeChannelId !== undefined ? routerParams.storeChannelId : StoreChannelType.MOBILE_PORTAL
  return storeChannelId
}

const decodeJwtResponse = function (data) {
  return !isEmptyString(data) ? jwtDecode(data) : null
}

const getDataLocale = function (value) {
  let index = languages.findIndex(x => x.locale === value)
  if (index !== -1) {
    return languages[index].dataLocale
  }
  return languages[1].dataLocale
}

export const currentEntityId = function () {
  let entityId = sessionStorage.getItem('entityId')
  if (!isNull(entityId)) {
    return entityId
  }
  return 0
}

export default {
  isNull,
  isEmptyString,
  getStringValue,
  getNumberValue,
  hasAction,
  isAdmin,
  isSuperAdmin,
  getFirstLastName,
  validateEmail,
  validateFullname,
  hasSpecialCharacter,
  removeSpaceInString,
  convertFormatNumberToMoney,
  formatInteger,
  isEntityLocked,
  isCurrentEntityLocked,
  isEntityExpired,
  isCurrentEntityExpired,
  isSupplier,
  isAgent,
  hasSystemRole,
  isEntityType,
  validatePhone,
  isCustomer,
  validUsername,
  hasEntityRoles,
  roleInEntity,
  SHA256,
  removeDoubleSlashesFromUrl,
  getFirstCharacterInString,
  convertToArrayId,
  getDefaultStoreChannelName,
  concatFullAddress,
  concatSuffixPrefix,
  isStringTrue,
  getReconciliationStatusText,
  getVoucherStatusText,
  getGender,
  sysRoleName,
  roleInEntityName,
  getEntityTypeName,
  paymentMethodTypeName,
  getHistoryActionName,
  parseVoucherHistoryDescription,
  removeVietnameseTones,
  concatFullname,
  randomString,
  ellipsisLongText,
  getSessionKey,
  getBankTransactionStatus,
  getStoreChannelsIdInRouteParams,
  decodeJwtResponse,
  getDataLocale
}
